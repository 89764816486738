import { initialize } from 'launchdarkly-js-client-sdk'
import type { LDClient, LDContext } from 'launchdarkly-js-client-sdk'
import { useAuthStore } from '@/stores/auth'

class FeatureFlagService {
  private ldClient: LDClient | null = null

  composeContext(): LDContext {
    const authStore = useAuthStore()
    return {
      kind: 'multi',
      organization: {
        key: authStore.user?.impersonate?.client_id || authStore.user?.client_id, // use the client_id as the organization key
        name: authStore.user?.client?.name
      },
      user: {
        key: authStore.user?.impersonate?.account_id || authStore.user?.account_id, // Use the account_id as the user key
        name: authStore.user?.user?.name
      }
    }
  }

  initializeLaunchDarkly() {
    if (this.ldClient) {
      return this.ldClient
    }

    this.ldClient = initialize(
      import.meta.env.VITE_LAUNCHDARKLY_CLIENT_SIDE_ID,
      this.composeContext()
    )
    return this.ldClient
  }

  getLdClient() {
    if (!this.ldClient) {
      throw new Error('LDClient is not initialized. Call initializeLaunchDarkly() first.')
    }
    return this.ldClient
  }

  reidentify() {
    this.ldClient?.identify(this.composeContext())
  }

  onReady(callback: () => void) {
    this.getLdClient().on('ready', callback)
  }

  onChange(callback: () => void) {
    this.getLdClient().on('change', callback)
  }

  onError(callback: () => void) {
    this.getLdClient().on('error', callback)
  }

  getFlags() {
    return this.getLdClient().allFlags()
  }

  destroy() {
    if (this.ldClient) {
      this.ldClient.close()
      this.ldClient = null
    }
  }
}

export const featureFlagService = new FeatureFlagService()
